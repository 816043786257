import { METRIC_KEY, METRIC_TYPE } from '@/core/constants'
import { formatCost } from './formatFunctions'

const metricsChart = {
  [METRIC_KEY.roas]: {
    label: 'ROAS',
    type: METRIC_TYPE.percentage,
    positiveIncrease: true,
  },
  [METRIC_KEY.conv_value]: {
    label: 'Conv. Value',
    type: METRIC_TYPE.number,
    positiveIncrease: true,
  },
  [METRIC_KEY.cost]: {
    label: 'Cost',
    type: METRIC_TYPE.currency,
    positiveIncrease: false,
  },
  [METRIC_KEY.conv]: {
    label: 'Conversions',
    type: METRIC_TYPE.number,
    positiveIncrease: true,
  },
  [METRIC_KEY.aov]: {
    label: 'Average Order Value',
    type: METRIC_TYPE.number,
    positiveIncrease: true,
  },
  [METRIC_KEY.cpa]: {
    label: 'CPA',
    type: METRIC_TYPE.currency,
    positiveIncrease: false,
  },
  [METRIC_KEY.clicks]: {
    label: 'Clicks',
    type: METRIC_TYPE.number,
    positiveIncrease: true,
  },
  [METRIC_KEY.conv_rate]: {
    label: 'Conv. Rate',
    type: METRIC_TYPE.percentage,
    positiveIncrease: true,
  },
  [METRIC_KEY.impressions]: {
    label: 'Impressions',
    type: METRIC_TYPE.number,
    positiveIncrease: true,
  },
  [METRIC_KEY.ctr]: {
    label: 'CTR',
    type: METRIC_TYPE.percentage,
    positiveIncrease: true,
  },
  [METRIC_KEY.impression_share]: {
    label: 'Impression Share',
    type: METRIC_TYPE.percentage,
    positiveIncrease: true,
  },
  [METRIC_KEY.avg_cpc]: {
    label: 'Avg. CPC',
    type: METRIC_TYPE.currency,
    positiveIncrease: false,
  },
}

export const mapMetrics = (metrics, anomalies, dateRange) => {
  const charts = []
  const keys = Object.keys(metricsChart)

  keys.forEach(key => {
    charts.push({
      label: metricsChart[key].label,
      link: key,
      selected: false,
      type: metricsChart[key].type,
      positiveIncrease: metricsChart[key].positiveIncrease,
      labels: dateRange,
      chartData: metrics[key].map(value => {
        if (value === null) return value
        return Number(formatCost(value))
      }),
      anomaly: anomalies[key + '_issue'],
    })
  })
  return charts
}
