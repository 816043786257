<template>
  <div
    class="arrow-breadcrumb"
    @click="handleClick"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <div
      class="arrow-background"
      :class="{ 'arrow-breadcrumb-hovered': isHovered }"
    >
      <arrow-left
        class="arrow-left"
        :stroke-color="strokeColor"
      />
      <arrow-center
        class="arrow-center"
        :stroke-color="strokeColor"
      />
      <arrow-right
        class="arrow-right"
        :stroke-color="strokeColor"
      />
    </div>
    <div class="breadcrumb-content flex gap-2 items-center">
      {{ breadcrumb.label }}
      <slot name="icon" />
    </div>
  </div>
</template>

<script>
import ArrowLeft from './arrow-left.vue'
import ArrowCenter from './arrow-center.vue'
import ArrowRight from './arrow-right.vue'

export default {
  name: 'ArrowBreadcrumb',
  components: {
    ArrowLeft,
    ArrowCenter,
    ArrowRight,
  },
  props: {
    breadcrumb: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isHovered: false,
    }
  },
  computed: {
    strokeColor() {
      return this.isHovered ? '#6366FA' : '#F0F1F3'
    },
  },
  methods: {
    handleClick() {
      this.$emit('click', this.breadcrumb)
    },
  },
}
</script>

<style lang="scss" scoped>
.arrow-breadcrumb {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 38px;
  cursor: pointer;
  white-space: nowrap;
}

.arrow-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  pointer-events: none;
}

.arrow-breadcrumb-hovered {
  .arrow-left,
  .arrow-center,
  .arrow-right {
    transition: filter 0.2s ease;
  }
}

.arrow-left {
  flex: 0 0 auto;
  width: 16px;
  transition: filter 0.2s ease;
}

.arrow-center {
  flex: 1 1 auto;
  height: 100%;
  min-width: 0;
  transition: filter 0.2s ease;
}

.arrow-right {
  flex: 0 0 auto;
  width: 16px;
  transition: filter 0.2s ease;
}

.breadcrumb-content {
  position: relative;
  z-index: 1;
  padding: 0 1.5rem;
}
</style>
