<template>
  <page class="main-container h-screen flex flex-col">
    <page-section bottom-border>
      <page-title>Anomaly Insights</page-title>
      <div class="flex flex-col md:flex-row gap-y-2 md:gap-x-6 pt-6 md:items-end">
        <search-input
          v-for="(input, index) in searchInputs"
          :key="index"
          v-model="selectedValue[input.id]"
          :class="input.class || 'w-250'"
          :input-id="input.id"
          :input-name="input.name"
          :label="input.label"
          :disabled="loading"
          :placeholder="input.placeholder"
          :options="getOptions(input.id)"
          :show-right-slot="input.slot && !loading"
          :columns="input.columns"
          :show-check-box="input.showCheckBox"
          :selected-items="selectedCheckbox"
          :selected-value="selectedValue[input.id]"
          :selected-chips="selectedCampaignsChip"
          @select-all="handleSelectAll"
          @option-selected="handleSelectCheckbox"
          @select-item="handleSelectedValue"
          @remove-chip="removeSelectedCampaignName"
        >
        </search-input>
        <merge-button-round
          class="px-10"
          :disabled="loading"
          @click="getInsights"
          >Analyze</merge-button-round
        >
      </div>
    </page-section>

    <div class="flex flex-1 overflow-hidden">
      <div class="overflow-y-auto h-screen base-scrollbar border-r border-neutral-50 bg-white">
        <ai-agent
          :site-id="siteId"
          :loading="loading"
        />
      </div>
      <performance-charts :loading="loading" />
    </div>
  </page>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { formatCost } from '@/utils/formatFunctions'

import Page from '@/components/base/page/Page.vue'
import PageSection from '@/components/base/page/PageSection.vue'
import SearchInput from '@/components/input/brightbid/SearchInput'
import MergeButtonRound from '@/components/btn/MergeButtonRound.vue'
import PerformanceCharts from './PerformanceCharts.vue'
import Toast from '@/components/shared/Toast'
import AiAgent from '@/views/troubleshooting/AiAgent.vue'

export default {
  name: 'TroubleShootingTree',
  components: { Page, PageSection, SearchInput, MergeButtonRound, PerformanceCharts, AiAgent },
  data() {
    return {
      selectedValue: {},
      selectedCheckbox: [],
      selectedCampaignsChip: [],
      searchInputs: [
        {
          id: 'search-campaign-1',
          name: 'search-campaign-1',
          label: 'Campaign Label',
          placeholder: 'Search Campaign...',
        },
        {
          id: 'search-campaign-2',
          name: 'search-campaign-2',
          label: 'Campaign Type',
          placeholder: 'Search Campaign...',
        },
        {
          id: 'search-campaign-3',
          name: 'search-campaign-3',
          label: 'Select Campaigns',
          placeholder: 'Search Campaign...',
          class: 'md:w-500',
          slot: true,
          columns: [
            { header: 'Campaigns', field: 'label', align: 'left' },
            { header: 'Cost (Last 30 days)', field: 'label2', align: 'right' },
          ],
          showCheckBox: true,
        },
      ],
      isChecked: false,
      loading: false,
    }
  },
  computed: {
    ...mapState('troubleshootingTree', ['campaigns', 'currency', 'selectedCampaigns']),
    ...mapState('site', ['selectedSite']),
    ...mapGetters('site', ['siteId']),
  },
  watch: {
    campaigns: {
      immediate: true,
      handler(newCampaigns) {
        this.selectedValue = {
          'search-campaign-1': { label: 'All Labels', value: newCampaigns.map(c => c.label) },
          'search-campaign-2': { label: 'All Types', value: newCampaigns.map(c => c.type) },
          'search-campaign-3': { label: 'All Campaigns', value: newCampaigns.map(c => c.name) },
        }

        this.selectedCheckbox = newCampaigns.map(campaign => ({
          value: campaign.name,
          label: campaign.name,
        }))

        this.updateCountOfSelectedCampaigns()
      },
    },
  },
  async mounted() {
    this.loading = true
    try {
      await this.fetchSiteDetails(this.siteId)
      await this.getCampaigns(this.siteId)
      this.updateSelectedCampaigns(this.campaigns)
      await this.analyzeAccount(this.siteId)
    } catch (error) {
      this.$toast.error({
        component: Toast,
        props: {
          title: 'Error',
          message: 'Failed to load data',
          type: 'error',
        },
      })
    } finally {
      this.loading = false
    }
  },
  methods: {
    ...mapActions('troubleshootingTree', [
      'getCampaigns',
      'analyzeAccount',
      'fetchSiteDetails',
      'updateSelectedCampaigns',
    ]),
    getOptions(id) {
      const selectedCampaign1 = this.selectedValue['search-campaign-1']?.value || []
      const selectedCampaign2 = this.selectedValue['search-campaign-2']?.value || []

      const optionMappings = {
        'search-campaign-1': [
          { value: '__ALL_LABELS__', label: 'All Labels' },
          ...Array.from(new Set(this.campaigns.map(c => c.label))).map(label => ({ value: label, label })),
        ],
        'search-campaign-2': [
          { value: '__ALL_TYPES__', label: 'All Types' },
          ...Array.from(
            new Set(
              this.campaigns
                .filter(c => selectedCampaign1.includes('__ALL_LABELS__') || selectedCampaign1.includes(c.label))
                .map(c => c.type),
            ),
          ).map(type => ({ value: type, label: type })),
        ],
        'search-campaign-3': this.campaigns
          .filter(c => selectedCampaign1.includes(c.label) && selectedCampaign2.includes(c.type))
          .sort((a, b) => b.metrics_cost - a.metrics_cost)
          .map(c => ({
            value: c.name,
            label: c.name,
            label2: `${formatCost(c.metrics_cost)} ${this.currency}`,
          })),
      }

      return optionMappings[id] || []
    },
    async getInsights() {
      this.loading = true
      try {
        if (!this.selectedCampaigns.length) throw Error('Please select a campaign')
        await this.analyzeAccount(this.siteId)
      } catch (error) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: error.message,
            type: 'error',
          },
        })
      } finally {
        this.loading = false
      }
    },
    handleSelectAll(isChecked) {
      const selectedCampaign1 = this.selectedValue['search-campaign-1']?.value || []
      const selectedCampaign2 = this.selectedValue['search-campaign-2']?.value || []

      // Filter campaigns based on selected labels and types
      const filteredCampaigns = this.campaigns.filter(
        c =>
          (selectedCampaign1.includes('all') || selectedCampaign1.includes(c.label)) &&
          (selectedCampaign2.includes('all') || selectedCampaign2.includes(c.type)),
      )

      if (isChecked[0]) {
        this.selectedCheckbox = filteredCampaigns.map(campaign => ({
          value: campaign.name,
          label: campaign.name,
        }))
        this.handleSelectedValue(
          { label: `${filteredCampaigns.length} Campaign(s) Selected`, value: filteredCampaigns.map(c => c.name) },
          'search-campaign-3',
        )
      } else {
        this.selectedCheckbox = []
        this.handleSelectedValue({ label: 'No Campaigns Selected', value: [] }, 'search-campaign-3')
      }

      this.updateCountOfSelectedCampaigns()
    },
    handleSelectCheckbox(value) {
      const index = this.selectedCheckbox.findIndex(item => item.value === value.value)

      if (index === -1) {
        this.selectedCheckbox.push(value)
      } else {
        this.selectedCheckbox.splice(index, 1)
      }

      this.updateCountOfSelectedCampaigns()

      this.handleSelectedValue(
        { label: 'Selected Campaigns', value: this.selectedCheckbox.map(item => item.value) },
        'search-campaign-3',
      )
    },
    updateCountOfSelectedCampaigns() {
      const selectedCount = this.selectedCheckbox.length
      if (selectedCount === 0) {
        this.selectedCampaignsChip = []
      } else {
        this.selectedCampaignsChip = [
          {
            value: `selected-campaigns`,
            label: `${selectedCount}`,
          },
        ]
      }
    },
    removeSelectedCampaignName() {
      this.selectedCheckbox = []

      this.selectedValue['search-campaign-3'] = {
        label: 'Selected Campaigns',
        value: this.selectedCheckbox.map(item => item.value),
      }

      this.updateCountOfSelectedCampaigns()
      this.updateSelectedCampaigns([])

      this.$forceUpdate()
    },
    handleSelectedValue(selectedItem, inputId) {
      const addAllTypes = (key, label, value) => {
        this.selectedValue[key] = { label, value }
      }

      const updateSelectedCheckbox = campaigns => {
        this.selectedCheckbox = campaigns.map(campaign => ({
          value: campaign.name,
          label: campaign.name,
        }))

        this.updateSelectedCampaigns(campaigns)
      }

      const updateLastSelectedValue = filteredCampaigns => {
        this.selectedValue['search-campaign-2'] = {
          label: 'All Types',
          value: [...new Set(filteredCampaigns.map(c => c.type))],
        }
        this.selectedValue['search-campaign-3'] = {
          label: 'All Campaigns',
          value: filteredCampaigns.map(c => c.name),
        }
      }

      this.selectedValue = {
        ...this.selectedValue,
        [inputId]: selectedItem,
      }

      switch (inputId) {
        case 'search-campaign-1': {
          if (selectedItem.value === '__ALL_LABELS__') {
            addAllTypes(
              'search-campaign-1',
              'All Labels',
              this.campaigns.map(c => c.label),
            )
          }

          const selectedLabels = this.selectedValue['search-campaign-1']?.value || []
          const filteredCampaigns = this.campaigns.filter(c => selectedLabels.includes(c.label))

          updateLastSelectedValue(filteredCampaigns)
          updateSelectedCheckbox(filteredCampaigns)
          break
        }

        case 'search-campaign-2': {
          if (selectedItem.value === '__ALL_TYPES__') {
            addAllTypes(
              'search-campaign-2',
              'All Types',
              this.campaigns.map(c => c.type),
            )
          }

          const selectedLabels = this.selectedValue['search-campaign-1']?.value || []
          const selectedTypes = this.selectedValue['search-campaign-2']?.value || []
          const filteredCampaigns = this.campaigns.filter(
            c => selectedLabels.includes(c.label) && selectedTypes.includes(c.type),
          )

          updateLastSelectedValue(filteredCampaigns)
          updateSelectedCheckbox(filteredCampaigns)
          break
        }

        case 'search-campaign-3': {
          const selectedCampaigns = this.campaigns.filter(c => selectedItem.value.includes(c.name))
          updateSelectedCheckbox(selectedCampaigns)
          break
        }

        default:
          break
      }

      this.$emit('update:selected-items', this.selectedCheckbox)

      this.updateCountOfSelectedCampaigns()
    },
  },
}
</script>
