<template>
  <div class="max-w-120 text-black chat-container">
    <div
      ref="messagesContainer"
      class="p-4 overflow-y-auto base-scrollbar container pb-40 messages-container"
    >
      <div class="flex flex-col gap-y-2">
        <div class="flex flex-row gap-x-2">
          <img v-bind="brightBidLogo" />
          <div class="flex flex-col gap-y-2 bg-neutral-0 p-4 rounded-2xl w-100">
            <h4 class="h4">Welcome back, {{ user.given_name }}!</h4>
            <p
              v-if="loading"
              class="animate-pulse"
            >
              I'm analyzing your account to provide the best optimizations! 🔎
            </p>
            <p v-else>
              <span
                v-for="(message, index) in welcomeMessage"
                :key="index"
                class="fade-in"
              >
                {{ message }}
              </span>
            </p>
          </div>
        </div>
      </div>

      <!-- Messages from user & AI -->
      <div
        v-for="(message, index) in messages"
        :key="index"
        class="flex flex-row gap-x-2 justify-center my-3"
      >
        <img
          v-if="message.sender === 'AI'"
          v-bind="brightBidLogo"
        />
        <div
          :class="[
            'py-2 px-4 rounded-2xl bg-neutral-0 inline-block',
            message.sender === 'User' ? 'ml-auto' : 'mr-auto',
          ]"
        >
          {{ message.text }}
        </div>
      </div>

      <!-- Loading state for AI response -->
      <div
        v-if="isResponseLoading && !loading"
        class="flex justify-center items-baseline my-3 text-gray-500 animate-pulse"
      >
        <p class="ml-2">BrightBid AI is typing</p>
        <loader-ellipsis :size="2" />
      </div>
    </div>

    <!-- Suggestion buttons -->
    <div class="flex flex-row gap-2 my-4 w-full font-medium bg-white px-4">
      <div
        v-for="(button, index) in buttons"
        :key="index"
        :class="[
          'cursor-pointer bg-bb-secondary-purple text-bb-brand-purple px-4 py-1 rounded-full border border-bb-secondary-purple hover:border-bb-brand-purple duration-300 ease-linear',
          loading ? 'opacity-50 cursor-not-allowed hover:border-bb-secondary-purple' : '',
        ]"
        @click="!loading && sendMessage(button.label)"
      >
        {{ button.label }}
      </div>
    </div>

    <!-- User input & send button -->
    <div class="relative w-full max-w-120 px-4">
      <input
        v-model="userMessage"
        type="text"
        class="border bg-neutral-0 border-neutral-50 rounded-2xl h-13 w-full pl-4"
        placeholder="Message BrightBid AI..."
        @keyup.enter="sendMessage(userMessage)"
      />
      <merge-button-round
        class="absolute right-6 top-2 custom-button"
        :disabled="loading"
        @click="sendMessage(userMessage)"
      >
        <ic-arrow-up size="16" />
      </merge-button-round>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import MergeButtonRound from '@/components/btn/MergeButtonRound.vue'
import LoaderEllipsis from '@/components/loader/EllipsisLoader'
import IcArrowUp from '@/components/icon/brightbid/ic-arrow-up.vue'

export default {
  name: 'AiAgent',
  components: { MergeButtonRound, LoaderEllipsis, IcArrowUp },
  props: {
    siteId: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userMessage: '',
      welcomeMessage: '',
      messages: [],
      buttons: [{ label: 'Explain the results 💡' }, { label: 'What should I do? 🔎' }],
      isResponseLoading: false,
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('troubleshootingTree', ['accountAnalysis']),
    ...mapGetters('troubleshootingTree', ['sessionId']),
    brightBidLogo() {
      return {
        src: '/content/images/brightbid/colored-brightbid-logo-only.svg',
        alt: '',
        class: 'rounded-full border border-neutral-50 h-8 w-8 p-0.5',
      }
    },
  },
  watch: {
    loading(newValue) {
      if (newValue) {
        this.clearMessages()
      } else if (this.accountAnalysis?.welcome_message) {
        this.typeWelcomeMessage(this.accountAnalysis.welcome_message)
      }
    },
  },
  methods: {
    ...mapActions('troubleshootingTree', ['getCampaigns', 'analyzeAccount', 'sendChatMessage']),
    async sendMessage(content) {
      if (!content.trim()) return

      try {
        this.isResponseLoading = true
        this.messages.push({ sender: 'User', text: content })

        if (content === this.userMessage) {
          this.userMessage = ''
        }

        await this.$nextTick()
        this.scrollToBottom()

        const reply = await this.sendChatMessage({
          siteId: this.siteId,
          sessionId: this.sessionId,
          content,
        })

        this.messages.push({ sender: 'AI', text: reply })

        if (content === this.userMessage) {
          this.userMessage = ''
        }

        await this.$nextTick()
        this.scrollToBottom()
      } catch (error) {
        this.messages.push({
          sender: 'AI',
          text: "I'm sorry, I couldn't process your request. Please try again later or ask something else.",
        })
      } finally {
        this.isResponseLoading = false
      }
    },
    scrollToBottom() {
      const container = this.$refs.messagesContainer
      if (container) {
        container.scrollTo({
          top: container.scrollHeight,
          behavior: 'smooth',
        })
      }
    },
    typeWelcomeMessage(fullMessage) {
      this.welcomeMessage = []
      const words = fullMessage.split(' ')
      const messageChunk = []
      let message = []

      words.forEach((word, index) => {
        message.push(word)
        if (message.length >= 7 || index === words.length - 1) {
          messageChunk.push(message.join(' '))
          message = []
        }
      })

      let index = 0

      const displayMessage = () => {
        if (index < messageChunk.length) {
          this.welcomeMessage.push(messageChunk[index])
          index++
          setTimeout(displayMessage, 700)
        }
      }

      displayMessage()
    },
    clearMessages() {
      this.messages = []
    },
  },
}
</script>

<style scoped>
.custom-button {
  padding: 8px;
}
.fade-in {
  opacity: 0;
  animation: fadeIn 1.5s forwards;
}
.chat-container {
  display: flex;
  flex-direction: column;
  height: 65vh;

  .messages-container {
    flex: 1;
    overflow-y: auto;
  }

  .suggestions-container,
  .input-container {
    flex-shrink: 0;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 15;
  }
}
</style>
