import { render, staticRenderFns } from "./PerformanceCharts.vue?vue&type=template&id=840ea996&scoped=true"
import script from "./PerformanceCharts.vue?vue&type=script&lang=js"
export * from "./PerformanceCharts.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "840ea996",
  null
  
)

export default component.exports