<template>
  <div
    :id="chart.link"
    class="shadow-bb-shadow-light bg-white px-4 sm:px-6 py-4 border rounded-md border-neutral-50 relative"
  >
    <div
      class="flex gap-4 md:justify-between"
      @mouseleave="hideTooltip"
    >
      <div class="w-full sm:w-2/4 sm:flex-shrink-0 md:w-2/5">
        <div class="flex items-center gap-2">
          <p>{{ chart.label }}</p>
          <ic-info-solid
            v-if="chart.anomaly"
            :class="['transform rotate-180', comparisonColor]"
            size="18"
          />
        </div>
        <div class="flex flex-wrap gap-2 items-baseline">
          <p
            v-if="currentValue !== null"
            class="font-bold"
          >
            {{ formatNumber(currentValue) }}{{ symbol }}
          </p>
          <p
            v-else
            class="font-bold"
          >
            -
          </p>
          <p
            v-if="currentValue && comparison !== 0"
            class="text-bb-neutral-gray pl-1"
          >
            <span :class="['font-bold', comparisonColor]"> {{ formattedComparison }}%</span> vs previous week
          </p>
        </div>
        <div
          :id="`tooltip-${chart.link}`"
          class="absolute opacity-0 bg-white border border-bb-neutral-50 rounded-lg px-3 py-1 shadow-bb-shadow-light"
        >
          <div class="flex gap-2">
            <ic-info-solid
              v-if="tooltipText.icon"
              class="mt-1 transform rotate-180"
              size="18"
            />
            <div>
              <p class="text-xs font-medium">{{ tooltipText.title }}</p>
              <p class="font-bold">{{ tooltipText.body }}{{ symbol }}</p>
            </div>
          </div>
        </div>
      </div>

      <div
        :id="`line-chart-${chart.link}`"
        class="w-full md:w-3/5"
      >
        <LineChart
          ref="lineChart"
          :height="40"
          :chart-data="chartData"
          :options="options"
        />
      </div>
    </div>
  </div>
</template>

<script>
import IcInfoSolid from '@/components/icon/brightbid/ic-info-solid.vue'
import LineChart from '@/components/chart/base/LineChart.vue'
import { formatNumberWithSpaces } from '@/utils/formatFunctions'

export default {
  name: 'PerformanceChartCard',
  components: {
    IcInfoSolid,
    LineChart,
  },
  props: {
    chart: {
      type: Object,
      required: true,
    },
    currency: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      chartData: {
        labels: this.chart.labels,
        datasets: [
          {
            borderColor: '#6366FA',
            backgroundColor: '#fff',
            data: this.chart.chartData,
            borderWidth: 1,
            tension: 0.1,
            pointRadius: 0,
          },
        ],
      },
      tooltipText: {
        title: '',
        body: '',
        icon: false,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              display: false,
            },
          ],
          yAxes: [
            {
              display: false,
            },
          ],
        },
        tooltips: {
          mode: 'nearest',
          intersect: false,
          enabled: false,
          custom: this.customTooltipHandler,
        },
      },
    }
  },
  computed: {
    currentValue() {
      const lastValue = this.chart.chartData[this.chart.chartData.length - 1]
      return lastValue === 0 || lastValue ? lastValue : null
    },
    symbol() {
      switch (this.chart.type) {
        case 'currency':
          return ` ${this.currency}`
        case 'percentage':
          return '%'
        default:
          return ''
      }
    },
    comparison() {
      const last = Number(this.currentValue)
      const secondToLast = Number(this.chart.chartData[this.chart.chartData.length - 2])

      if (secondToLast === 0) {
        if (last === 0) return 0
        return last > 0 ? 100 : -100
      }

      const percentageDiff = ((last - secondToLast) / secondToLast) * 100
      return Math.round(percentageDiff)
    },
    formattedComparison() {
      switch (true) {
        case this.comparison > 0:
          return `+${this.comparison}`
        case this.comparison < 0:
          return this.comparison
        default:
          return 0
      }
    },
    comparisonColor() {
      const threshold = 2
      switch (true) {
        case Math.abs(this.comparison) <= threshold:
          return 'text-bb-neutral-gray'
        // Good performance
        case this.comparison > threshold && this.chart.positiveIncrease:
        case this.comparison < -threshold && !this.chart.positiveIncrease:
          return 'text-success'
        // Bad performance
        case this.comparison < -threshold && this.chart.positiveIncrease:
        case this.comparison > threshold && !this.chart.positiveIncrease:
          return 'text-bb-red-error'
        default:
          return 'text-bb-neutral-gray'
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      const lineChart = this.$refs.lineChart
      if (!lineChart) return
      this.addGradient()
      if (this.chart.anomaly) {
        this.highlightArea()
      }
      lineChart._data._chart.update()
    })
  },
  methods: {
    //TODO - move to helpers/mixins?
    addGradient() {
      const colorStops = [
        { offset: 0, color: 'rgba(119, 117, 255, 0.4)' },
        { offset: 0.6, color: 'rgba(99, 141, 250, 0.1)' },
      ]

      const gradient = this.createGradient(colorStops)
      this.$refs.lineChart.chartData.datasets[0].backgroundColor = gradient
    },
    highlightArea() {
      this.$refs.lineChart.addPlugin({
        beforeDraw: chart => {
          const ctx = chart.chart.ctx
          const chartArea = chart.chartArea
          const meta = chart.getDatasetMeta(0)

          const start = meta.data[meta.data.length - 2]._model.x
          const stop = meta.data[meta.data.length - 1]._model.x

          let colorStops = []
          if (this.comparisonColor === 'text-success') {
            colorStops = [
              { offset: 0, color: 'rgba(131, 242, 63, 0)' },
              { offset: 0.4, color: 'rgba(63, 242, 92, 0.216)' },
              { offset: 0.7, color: 'rgba(63, 242, 81, 0.216)' },
              { offset: 1, color: 'rgba(67, 242, 63, 0)' },
            ]
          } else {
            colorStops = [
              { offset: 0, color: 'rgba(242, 63, 68, 0)' },
              { offset: 0.243, color: 'rgba(242, 63, 68, 0.216)' },
              { offset: 0.7, color: 'rgba(242, 63, 68, 0.216)' },
              { offset: 1, color: 'rgba(242, 63, 68, 0)' },
            ]
          }

          const gradient = this.createGradient(colorStops)
          ctx.save()
          ctx.fillStyle = gradient
          ctx.fillRect(start, chartArea.top, stop - start, chartArea.bottom - chartArea.top)
          ctx.restore()
        },
      })
    },
    createGradient(colorStops) {
      const canvas = document.getElementById('line-chart')
      if (!canvas) return
      const ctx = canvas.getContext('2d')
      const gradient = ctx.createLinearGradient(canvas.width, canvas.height / 5, canvas.width, canvas.height / 1)
      colorStops.forEach(colorStop => {
        gradient.addColorStop(colorStop.offset, colorStop.color)
      })
      return gradient
    },
    customTooltipHandler(context) {
      // todo - check if hovering over anomaly -> display icon
      const chartContainer = document.getElementById(this.chart.link)
      const canvas = document.getElementById(`line-chart-${this.chart.link}`)
      const tooltip = document.getElementById(`tooltip-${this.chart.link}`)

      const chartRect = chartContainer.getBoundingClientRect()
      const canvasRect = canvas.getBoundingClientRect()
      const left = canvasRect.left - chartRect.left + context.caretX - tooltip.offsetWidth
      const top = canvasRect.top - chartRect.top + context.caretY - tooltip.offsetHeight - 10

      if (context?.body?.length && context?.title?.length) {
        this.tooltipText.title = context.title[0]
        this.tooltipText.body = this.formatNumber(Number(context.body[0].lines[0]))
      }

      tooltip.style.position = 'absolute'
      tooltip.style.left = `${left}px`
      tooltip.style.top = `${top}px`
      tooltip.style.opacity = 1
      tooltip.style.zIndex = 1000
    },
    hideTooltip() {
      const tooltip = document.getElementById(`tooltip-${this.chart.link}`)
      tooltip.style.opacity = 0
      tooltip.style.zIndex = -1
    },
    formatNumber(num) {
      return formatNumberWithSpaces(num)
    },
  },
}
</script>
