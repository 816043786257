<template>
  <page-section
    ref="scrollContainer"
    class="bg-neutral-0 flex-1 overflow-auto base-scrollbar"
  >
    <!-- loading state -->
    <div v-if="loading">
      <PerformanceChartsLoader class="max-w-screen-xl mx-auto" />
    </div>
    <div
      v-else-if="error"
      class="flex flex-col items-center justify-center mt-20"
    >
      <img
        src="/content/images/brightbid/robot-error.svg"
        alt="Error occurred"
      />
      <p class="text-center font-medium mt-4 bb-text-headers text-base">Content failed to load</p>
      <p class="text-center mt-2 w-1/2">
        We couldn’t render some data. Please try refreshing the page or checking your internet connection. If the issue
        persists,
        <a
          class="cursor-pointer"
          @click="openSupport"
          >contact support</a
        >.
      </p>
    </div>
    <div
      v-else
      class="relative h-full overflow-y-auto pb-40 pt-4"
    >
      <div class="flex gap-2 items-end mb-4">
        <h4>Performance - Last 6 months</h4>
        <p class="font-light text-bb-neutral-gray">({{ formatDate(startDate) }} - {{ formatDate(endDate) }})</p>
      </div>

      <div>
        <!-- breadcrumbs -->
        <div class="flex center flex-wrap gap-2">
          <arrow-breadcrumbs
            v-for="(item, index) in breadcrumbs"
            :key="index"
            :breadcrumb="item"
            @click="scrollToChart(item.link)"
          />
        </div>

        <!-- charts -->
        <div
          v-if="performanceCharts.length"
          class="flex-col center space-y-3 pt-3"
        >
          <performance-chart-card
            v-for="(item, index) in performanceCharts"
            :key="index"
            :chart="item"
            :currency="currency"
          />
        </div>
      </div>
    </div>
  </page-section>
</template>

<script>
import ArrowBreadcrumbs from '@/components/breadcrumb/ArrowBreadcrumb.vue'
import PerformanceChartCard from '@/components/card/PerformanceChartCard.vue'
import PageSection from '@/components/base/page/PageSection.vue'
import PerformanceChartsLoader from './PerformanceChartsLoader.vue'
import { METRIC_KEY } from '@/core/constants'
import { mapMetrics } from '@/utils/troubleshootingTree'
import { mapState, mapGetters } from 'vuex'
import dayjs from 'dayjs'

export default {
  name: 'PerformanceCharts',
  components: { ArrowBreadcrumbs, PerformanceChartCard, PageSection, PerformanceChartsLoader },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('troubleshootingTree', ['currency', 'error']),
    ...mapGetters('troubleshootingTree', ['weeklyDateRange', 'metrics', 'anomalies', 'startDate', 'endDate']),
    ...mapGetters('site', ['siteId']),
    breadcrumbs() {
      return [
        {
          label: 'ROAS',
          link: [METRIC_KEY.roas],
          selected: false,
        },
        {
          label: 'Conv. Value & Cost',
          link: [METRIC_KEY.conv_value, METRIC_KEY.cost],
          selected: false,
        },
        {
          label: 'Conv. & AOV',
          link: [METRIC_KEY.conv, METRIC_KEY.aov],
          selected: false,
        },
        {
          label: 'CPA',
          link: [METRIC_KEY.cpa],
          selected: false,
        },
        {
          label: 'Clicks & Conv. Rate',
          link: [METRIC_KEY.clicks, METRIC_KEY.conv_rate],
          selected: false,
        },
        {
          label: 'Impressions & CTR',
          link: [METRIC_KEY.impressions, METRIC_KEY.ctr],
          selected: false,
        },
        {
          label: 'Imp. Share & Avg CPC',
          link: [METRIC_KEY.impression_share, METRIC_KEY.avg_cpc],
          selected: false,
        },
      ]
    },
    performanceCharts() {
      if (!this.metrics || !this.anomalies || !this.weeklyDateRange) {
        return []
      }
      return mapMetrics(this.metrics, this.anomalies, this.weeklyDateRange)
    },
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format('DD/MM/YYYY')
    },
    scrollToChart(links) {
      const scrollContainer = this.$refs.scrollContainer.$el

      this.$nextTick(() => {
        // todo - highlight correct cards
        const element = document.getElementById(links[0])

        if (element && scrollContainer) {
          const containerTop = scrollContainer.getBoundingClientRect().top
          const elementTop = element.getBoundingClientRect().top
          const scrollOffset = elementTop - containerTop

          scrollContainer.scrollTo({
            top: scrollContainer.scrollTop + scrollOffset,
            behavior: 'smooth',
          })
        }
      })
    },
    openSupport() {
      if (window.Intercom) {
        window.Intercom('show')
        return
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
