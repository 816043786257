<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="9.044 0 97.937 38"
    style="max-height: 500px"
    preserveAspectRatio="none"
    width="100%"
    :height="size"
  >
    <path
      :stroke="strokeColor"
      fill="white"
      d="M0.781432 2.96112C0.333086 2.05895 0.989335 1 1.99677 1H106.901C108.879 1 110.677 2.15058 111.506 3.94713L117.469 16.8757C118.091 18.2237 118.091 19.7764 117.469 21.1243L111.506 34.0529C110.677 35.8494 108.879 37 106.901 37H1.99678C0.989339 37 0.333086 35.941 0.781432 35.0389L7.40938 21.702C8.25524 20 8.25524 18 7.40938 16.298L0.781432 2.96112Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'ArrowCenter',
  props: {
    size: {
      type: [String, Number],
      default: 38,
    },
    strokeColor: {
      type: String,
      default: '#F0F1F3',
    },
  },
}
</script>

<style scoped lang="scss"></style>
