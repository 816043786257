<template>
  <div class="animate-pulse">
    <div class="w-full flex flex-col gap-3">
      <div class="flex pt-4 flex-col gap-3">
        <div class="h-5 bg-gray-300 rounded w-1/3"></div>
        <div class="flex center flex-wrap gap-4">
          <div
            v-for="i in elements"
            :key="i"
            class="flex flex-row gap-4 pt-2"
          >
            <div class="h-8 w-30 bg-gray-300 rounded"></div>
          </div>
        </div>
        <!-- charts -->
        <div
          v-for="i in elements"
          :key="i"
          class="flex flex-row gap-4"
        >
          <div
            class="shadow-bb-shadow-light bg-white border rounded-md border-neutral-50 px-6 py-3.5 flex flex-row gap-16 w-full justify-between"
          >
            <div class="flex-col w-1/3 space-y-2">
              <div class="h-5 bg-gray-300 rounded"></div>
              <div class="h-5 bg-gray-300 rounded"></div>
            </div>
            <div class="flex flex-row w-full">
              <div class="h-12 bg-gray-300 rounded w-full"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PerformanceChartsLoader',
  data() {
    return {
      elements: 5,
    }
  },
}
</script>
