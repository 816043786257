var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"max-w-120 text-black chat-container"},[_c('div',{ref:"messagesContainer",staticClass:"p-4 overflow-y-auto base-scrollbar container pb-40 messages-container"},[_c('div',{staticClass:"flex flex-col gap-y-2"},[_c('div',{staticClass:"flex flex-row gap-x-2"},[_c('img',_vm._b({},'img',_vm.brightBidLogo,false)),_c('div',{staticClass:"flex flex-col gap-y-2 bg-neutral-0 p-4 rounded-2xl w-100"},[_c('h4',{staticClass:"h4"},[_vm._v("Welcome back, "+_vm._s(_vm.user.given_name)+"!")]),(_vm.loading)?_c('p',{staticClass:"animate-pulse"},[_vm._v(" I'm analyzing your account to provide the best optimizations! 🔎 ")]):_c('p',_vm._l((_vm.welcomeMessage),function(message,index){return _c('span',{key:index,staticClass:"fade-in"},[_vm._v(" "+_vm._s(message)+" ")])}),0)])])]),_vm._l((_vm.messages),function(message,index){return _c('div',{key:index,staticClass:"flex flex-row gap-x-2 justify-center my-3"},[(message.sender === 'AI')?_c('img',_vm._b({},'img',_vm.brightBidLogo,false)):_vm._e(),_c('div',{class:[
          'py-2 px-4 rounded-2xl bg-neutral-0 inline-block',
          message.sender === 'User' ? 'ml-auto' : 'mr-auto',
        ]},[_vm._v(" "+_vm._s(message.text)+" ")])])}),(_vm.isResponseLoading && !_vm.loading)?_c('div',{staticClass:"flex justify-center items-baseline my-3 text-gray-500 animate-pulse"},[_c('p',{staticClass:"ml-2"},[_vm._v("BrightBid AI is typing")]),_c('loader-ellipsis',{attrs:{"size":2}})],1):_vm._e()],2),_c('div',{staticClass:"flex flex-row gap-2 my-4 w-full font-medium bg-white px-4"},_vm._l((_vm.buttons),function(button,index){return _c('div',{key:index,class:[
        'cursor-pointer bg-bb-secondary-purple text-bb-brand-purple px-4 py-1 rounded-full border border-bb-secondary-purple hover:border-bb-brand-purple duration-300 ease-linear',
        _vm.loading ? 'opacity-50 cursor-not-allowed hover:border-bb-secondary-purple' : '',
      ],on:{"click":function($event){!_vm.loading && _vm.sendMessage(button.label)}}},[_vm._v(" "+_vm._s(button.label)+" ")])}),0),_c('div',{staticClass:"relative w-full max-w-120 px-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userMessage),expression:"userMessage"}],staticClass:"border bg-neutral-0 border-neutral-50 rounded-2xl h-13 w-full pl-4",attrs:{"type":"text","placeholder":"Message BrightBid AI..."},domProps:{"value":(_vm.userMessage)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.sendMessage(_vm.userMessage)},"input":function($event){if($event.target.composing)return;_vm.userMessage=$event.target.value}}}),_c('merge-button-round',{staticClass:"absolute right-6 top-2 custom-button",attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.sendMessage(_vm.userMessage)}}},[_c('ic-arrow-up',{attrs:{"size":"16"}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }