export function formatNumberWithSpaces(value) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

//Format function to only display 2 decimals/no decimals if value is a whole number
export function formatCost(value) {
  if (typeof value !== 'number') {
    return 'Invalid number'
  }
  const formattedValue = value.toFixed(2)
  if (formattedValue.endsWith('.00')) {
    return value.toFixed(0)
  }
  return formattedValue
}
